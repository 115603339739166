.create-event-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.create-event-text {
  font-size: 12px;
  font-style: normal;
  color: #ebebeb;
  font-weight: 400;
  line-height: 16px;
  text-decoration: none;
}
.event-details-title {
  font-size: 16px;
  font-weight: 500;
  color: #19161d;
  text-wrap: nowrap !important;
}

.event-details-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.event-details-form {
  height: 48px;
  padding: 6px 12px;
  color: #53575a !important;
  font-size: 14px !important;
}

.event-details-form-with-icons {
  height: 48px;
  padding: 6px 12px;
  border-left: none !important;
  color: #53575a !important;
  font-size: 14px !important;
}

.event-details-calender {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06),
    0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 20px;
}

.event-details-button {
  padding: 6px 12px;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
}

.event-type-text {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  padding: 8px 12px !important;
  color: #19161d !important;
  border-radius: 40px !important;
  background: var(--Grey-Grey-200, #f2f2f2) !important;
}

.event-tag-text {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  padding: 8px 12px !important;
  color: #19161d !important;
  border-radius: 40px !important;
  background: white !important;
}

.input-group-text {
  background-color: white !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
  padding: 0.35rem 0rem 0.35rem 0.75rem !important;
}

.full-details-event-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.full-details-event-card-text {
  color: #53575a !important;
  font-size: 12px;
  font-weight: 400;
}

.full-details-event-description {
  font-size: 12px;
  color: #53575a !important;
}

ul.full-details-event-card-text li > a {
  color: #53575a !important;
  text-decoration: none;
}

.event-publish-button {
  padding: 14px 16px;
  font-size: 14px;
  font-weight: 400;
}

.select__input-container {
  padding: 6px 12px !important;
}
.event-label {
  font-size: 12px;
  text-wrap: nowrap !important;
}
