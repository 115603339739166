@import "~swiper/css";
@import "~swiper/css/effect-fade";
@import "~swiper/css/navigation";
@import "~swiper/css/pagination";


.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 30px;
    border-radius: 5px;
    transition: 0.4s;
    background-color: white;
  }

  .swiper-wrapper {
    align-items: center !important;
  }

  .swiper-slide {
    width: unset !important;
  }