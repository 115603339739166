@import "~react-big-calendar/lib/css/react-big-calendar.css";

.rbc-month-header {
  background-color: #ebf1fc;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}

.rbc-header {
  font-weight: 400;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-size: 14px;
}

.rbc-time-header-cell {
  background-color: #ebf1fc;
}

.rbc-month-view {
  border: 0px;
}

.rbc-time-view .rbc-row {
  min-height: unset;
}

.rbc-time-view {
  border: 0px;
}

.rbc-timeslot-group {
  border-bottom: 0.5px solid #ddd;
  min-height: 60px;
}

.rbc-time-slot {
  margin-top: auto;
  margin-top: auto;
}

.rbc-label {
  font-weight: 400;
  font-size: 12px;
  color: #86888a;
}

.rbc-button-link {
  font-size: 13px;
}
// .rbc-active {
//   background-color: black !important;
//   color: white !important;
// }
.rbc-btn-group > button {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  font-size: 14px;
}

.rbc-event-content {
  width: unset !important;
  line-height: unset !important;
  height: unset !important;
  min-height: unset !important;
  display: flex !important;
  justify-content: center !important;
}

.rbc-event-label {
  padding-right: unset !important;
}

.rbc-events-container {
  margin-right: unset !important;
}

.rbc-event .rbc-selected {
  border: none !important;
  align-items: center !important;
  background-color: #000000 !important;
  border-radius: none !important;
  -webkit-border-radius: none !important;
  -moz-border-radius: none !important;
  -ms-border-radius: none !important;
  -o-border-radius: none !important;
}

.rdrCalendarWrapper {
  width: 100%;
}
.rdrMonth {
  width: 100%;
}
