.sidebar-accordion > .accordion-button:focus {
  border-color: unset;
  box-shadow: unset;
}

.sidebar-accordion > .accordion-button{
  border-color: unset;
  background: unset;
  padding: unset;
}

.accordion-item {
  background-color: unset; 
  border: unset;
}

.sidebar-accordion-item.accordion-item{
  background-color: unset !important;
  border: unset !important;
}

.sidebar-accordion-item .accordion-button{
  padding: 10px;
}

.sidebar-accordion-item .accordion-button:not(.collapsed) {
  border-radius: 16px;
  background: #ffffff;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button{
  padding: 16px !important;
  background-color: transparent !important;
}