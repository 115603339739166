
.sidebar-menu .nav-link{
  color: #000;
  font-weight: 400;
  font-size: 14px;
}

.sidebar-menu .nav-link.active{
  color: #000;
  font-weight: 400;
  font-size: 14px;
  background-color: white;
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}