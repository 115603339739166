.page-link {
    background: none !important;
    border: none !important;
    color: #19161D !important;
}

.active > .page-link {
    background-color: #EBF1FC !important;
    color: #19161D !important;
    border-radius: 3rem;
    -webkit-border-radius: 3rem;
    -moz-border-radius: 3rem;
    -ms-border-radius: 3rem;
    -o-border-radius: 3rem;
}