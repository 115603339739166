@import "./components/buttons";
@import "./components/accordion";
@import "./components/sidebar";
@import "./components/breadcrumb";
@import "./components/notfound";
@import "./components//pagination";
@import "./components/swiper";
@import "~bootstrap/scss/bootstrap";
@import "./components/fileupload";


@import "~react-date-range/dist/styles.css";
@import "~react-date-range/dist/theme/default.css";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;500&display=swap");

@import "./components/event";
@import "./components/users";
@import "./components/dropdown";
@import "./components/settings";
@import "./components/calender";

* {
  font-family: "Inter", sans-serif;
}

.bg-white-80 {
  background-color: rgba(255, 255, 255, 0.8);
}

.main-bg {
  background: var(
    --gradients-blue-moon,
    radial-gradient(
      116.01% 64.3% at 17.78% 99.56%,
      rgba(90, 126, 218, 0.5) 0%,
      rgba(81, 253, 212, 0) 100%
    ),
    linear-gradient(90deg, #c9d6ff 0%, #e9e4f0 100%)
  );
}

.bg-login {
  background: url("../images/bg_signin.webp") no-repeat !important;
  background-position: center;
  background-size: cover;
}

.title-rule::after {
  content: "";
  display: inline-block;
  position: relative;
  width: 100%;
  border-top: 1px solid gray;
  margin-left: 5px;
  /* Adjust as needed */
  vertical-align: middle;
}

.line {
  content: "";
  display: inline-block;
  position: relative;
  width: 100%;
  border-top: 1px solid rgba(128, 128, 128, 0.219);
  margin-top: 1.5%;
  /* Adjust as needed */
  vertical-align: middle;
}

.contact-text {
  width: 250px;
}

.kindly-text {
  width: 300px;
}

.form-col {
  padding: 10px 30px;
}

hr {
  border-color: #adacac;
}

input.form-control-lg,
select.form-select-lg {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  font-size: 14px;
}

input.form-control::-webkit-input-placeholder,
select.form-select::-webkit-input-placeholder {
  font-size: 14px;
}

input.form-control:-moz-placeholder,
select.form-select:-moz-placeholder {
  font-size: 14px;
}

input.form-control::-moz-placeholder,
select.form-select::-moz-placeholder {
  font-size: 14px;
}

.form-control:focus,
.form-control:active,
.form-select:focus,
.form-select:active {
  border-color: #d4d4d4;
  outline: 0;
  box-shadow: unset;
}

input.form-control {
  border: 1px solid #ebebeb;
}

.custom-accordion .accordion-button {
  box-shadow: unset;
  border: unset;
  padding: unset !important;
  display: unset;
  background-color: unset !important;
}

.text-with-line::after {
  content: "";
  display: inline-block;
  width: 100%;
  border-bottom: 1px dashed #ccc;
  margin-left: 5px;
  height: 0.2px;
  vertical-align: middle;
}

.form-bg {
  background-color: #ffffff !important;
}

.fs-form-input {
  font-size: 14.3px;
}

/* Apply Bootstrap rounded class to the Select component */
.react-select-container {
  border-radius: 10px;
}

/* Customize the background color on hover */
.react-select__option--is-focused {
  background-color: #cff6ff;
}

.fs-button {
  font-size: 13px;
}

.event-card-text {
  font-size: 13px !important;
}

ul.event-card-text li > a {
  color: #53575a !important;
  text-decoration: none;
}

.event-card-title {
  font-size: 15px !important;
}

.event-pill {
  background-color: #f2f2f2 !important;
}

.react-select-container {
  border-radius: 20px;
  font-size: 14px !important;
  padding-top: 3px;
  height: 55px !important;
  font-weight: 400;
  text-wrap: nowrap;
}

.react-select-container:focus,
.react-select-container:active,
.react-select-containert:focus,
.react-select-container:active {
  border-color: #d4d4d4;
  outline: 0;
  box-shadow: unset;
}

.form-control,
.react-select-container {
  font-size: 16px;
}

.event-card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px;
}

.event-button {
  padding: 4px 8px;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
}

.highlight-checkbox .form-check-input[type="checkbox"]:not(:checked) {
  border-color: red;
  /* Example: Change border color to red */
}

.signup-button {
  width: 50%;
  margin-bottom: 10px;
}

.scroll {
  max-height: 50em !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
}

.eye-icon {
  cursor: pointer;
  margin-left: -25px;
}

@media screen and (max-width: 567px) {
  .signup-button {
    width: 100%;
    margin-bottom: 10px;
  }
}

.bg-enquiries {
  background: linear-gradient(90deg, #c9d6ff 0%, #e9e4f0 100%),
    radial-gradient(
      49.56% 104.89% at 17.78% 99.56%,
      rgba(90, 126, 218, 0.5) 0%,
      rgba(81, 253, 212, 0) 100%
    );
}

.modal-content {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  border: 0px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.modal-backdrop {
  background-color: #00000060;
}

.bg-pill {
  background-color: #f1f1f2 !important;
  font-size: 12px;
}

.sb-avatar__text {
  border: 2px white solid;
}

.select__control {
  border-radius: unset !important;
  // border-style: unset !important;
  // border-width: unset !important;
  padding: 2px;
  -webkit-border-radius: unset !important;
  -moz-border-radius: unset !important;
  -ms-border-radius: unset !important;
  -o-border-radius: unset !important;
}
.select__control:hover {
  border-color: unset !important;
}

.dropdown-heading{
  padding-top: 1.4rem !important;
  padding-bottom: 1.4rem !important;
}